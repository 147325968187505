import React from 'react';
import upIcon from "../icons/right-icon.svg"; // Toggle icon (will rotate)
import shieldIcon from "../icons/shield-icon.svg"; // Icons used in the right panel
import "../css/RightPanel.css";

const RightPanel = ({ isPanelOpen, togglePanel, sourceDocuments }) => {
  return (
    <div className={`right-panel ${isPanelOpen ? "open" : "collapsed"}`}>
      {isPanelOpen && (
        <>
          <div className="right-panel-header">
            <h3>References</h3>
          </div>

          <div className="source-content">
            {sourceDocuments.map((document, index) => (
              <div className="source-item" key={index}>
                <h3>{document}</h3>
              </div>
            ))}
          </div>
        </>
      )}

      <button className="right-panel-toggle" onClick={togglePanel}>
        <img
          src={upIcon}
          alt="toggle icon"
          className={`toggle-icon ${isPanelOpen ? "rotate" : ""}`}
        />
      </button>
    </div>
  );
};


export default RightPanel;
