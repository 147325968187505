import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/ResetPassword.css";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  // Extract token from URL query parameter
  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisibility(!newPasswordVisibility);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(!confirmPasswordVisibility);
  };

  const validatePasswords = () => {
    if (newPassword.length < 8) {
      setErrors((prev) => ({ ...prev, newPassword: "Password must be at least 8 characters long." }));
    } else if (newPassword !== confirmPassword) {
      setErrors((prev) => ({ ...prev, confirmPassword: "Passwords do not match." }));
    } else {
      setErrors({});
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    validatePasswords();
    if (Object.values(errors).some((error) => error)) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_PROD_BACKEND_URL}/auth/reset-password`, {
        token,
        newPassword,
      });

      setMessage(response.data.message);
      setTimeout(() => {
        navigate("/login");
      }, 3000); 
    } catch (error) {
      console.error("Error resetting password:", error.response?.data?.message || error.message);
      setErrors((prev) => ({ ...prev, form: error.response?.data?.message || "Failed to reset password." }));
    }
  };

  return (
    <div className="reset-password-container">
      <form className="reset-password-form" onSubmit={handleResetPassword}>
        <h2>Reset Password</h2>
        <div className="form-group">
          <label>New Password</label>
          <div className="password-container">
            <input
              type={newPasswordVisibility ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={toggleNewPasswordVisibility}
              className="toggle-password-btn"
            >
              {newPasswordVisibility ? "Hide" : "Show"}
            </button>
          </div>
          {errors.newPassword && <p className="error-text">{errors.newPassword}</p>}
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <div className="password-container">
            <input
              type={confirmPasswordVisibility ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              className="toggle-password-btn"
            >
              {confirmPasswordVisibility ? "Hide" : "Show"}
            </button>
          </div>
          {errors.confirmPassword && <p className="error-text">{errors.confirmPassword}</p>}
        </div>
        {errors.form && <p className="error-text">{errors.form}</p>}
        {message && <p className="success-text">{message}</p>}
        <button type="submit" className="reset-password-button">
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
