import React, { useState, useEffect, useRef } from 'react';
import "../css/CaseList.css";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import hamburgerIcon from "../icons/hamburger-icon.svg";
import caseFinderHeaderIcon from "../icons/casefinder-header-icon.svg";
import backIcon from "../icons/Back-button-icon.svg";
import searchIcon from "../icons/search-icon.svg";

function CaseList({ toggleMobileSidebar }) {
    const [cases, setCases] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');  // State for search query
    const [selectedFilter1, setSelectedFilter1] = useState('All');
    const [selectedFilter2, setSelectedFilter2] = useState('All');
    const [selectedFilter3, setSelectedFilter3] = useState('All');
    const [cursor, setCursor] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const observer = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        fetchCases();
    }, []);

    useEffect(() => {
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                fetchCases();
            }
        });

        const sentinel = document.querySelector('#sentinel');
        if (sentinel) observer.current.observe(sentinel);

        return () => {
            if (sentinel) observer.current.unobserve(sentinel);
        };
    }, [hasMore, loading]);

    const fetchCases = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/fetchCases`, {
                params: {
                    cursor: cursor,
                    limit: 10,
                },
            });

            if (response.data.cases.length > 0) {
                setCases((prevCases) => [...prevCases, ...response.data.cases]);
                setCursor(response.data.cursor);
                setHasMore(response.data.cursor !== null);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching cases:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleThreadClick = (caseItem) => {
        const { url, caseName, case_type, date, judges, citation } = caseItem;
        uploadAndProcessPdf(url, caseName, { case_type, date, judges, citation });
    };

    const uploadAndProcessPdf = async (url, caseName, additionalCaseData) => {
        try {
            const pdfResponse = await axios.get(url, { responseType: 'blob' });
            if (!pdfResponse || !pdfResponse.data) {
                console.error('Failed to download the PDF from the provided URL');
                return;
            }

            const formData = new FormData();
            formData.append('file', new File([pdfResponse.data], `${caseName}.pdf`, { type: 'application/pdf' }));

            const uploadResponse = await axios.post(`${process.env.REACT_APP_PROD_AI_URL}/chat/upload_case_pdf`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (uploadResponse.status === 200) {
                const document_id = uploadResponse.data.case_document_id;
                localStorage.setItem('case_document_id', document_id);
                navigate('/addcase', {
                    state: { 
                        pdfUrl: url, 
                        caseName, 
                        document_id, 
                        ...additionalCaseData 
                    }
                });
            } else {
                console.error('Error uploading the file:', uploadResponse.data.detail);
            }
        } catch (error) {
            console.error('Error processing PDF:', error);
        }
    };

    const removePdfExtension = (title) => {
        return title.replace(/\.pdf$/i, '');
    };

    const filteredCases = cases.filter(caseItem =>
        removePdfExtension(caseItem.caseName).toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedFilter1 === 'All' || caseItem.category === selectedFilter1) &&
        (selectedFilter2 === 'All' || caseItem.type === selectedFilter2) &&
        (selectedFilter3 === 'All' || caseItem.year === selectedFilter3)
    );

    return (
        <div className="caselist">
            <div className="caselist-header">
                <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button>
                <h3>Case Judgements</h3>
                <button className="landmark-back-button" onClick={() => navigate(-1)}><span><img src={backIcon} alt="close icon" /></span></button>
            </div>
            <div className='section-divider'></div>
            
            <div className='search-and-filters'>
                <div className="caselist-search-bar">
                    <div className="caselist-search-icon">
                        <img className="casefinder-search-icon" src={searchIcon} alt="" />
                    </div>
                    <input
                        type="text"
                        placeholder="Search Court Judgment"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

                <div className="filters">
                    <select value={selectedFilter1} onChange={(e) => setSelectedFilter1(e.target.value)}>
                        <option value="All">All Categories</option>
                        <option value="Category A">Category A</option>
                        <option value="Category B">Category B</option>
                        <option value="Category C">Category C</option>
                    </select>
                    <select value={selectedFilter2} onChange={(e) => setSelectedFilter2(e.target.value)}>
                        <option value="All">All Types</option>
                        <option value="Type 1">Type 1</option>
                        <option value="Type 2">Type 2</option>
                        <option value="Type 3">Type 3</option>
                    </select>
                    <select value={selectedFilter3} onChange={(e) => setSelectedFilter3(e.target.value)}>
                        <option value="All">All Years</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                    </select>
                </div>
            </div>

            <div className="caselist-content" >
                <div className="caselist-thread-section">
                    {filteredCases.map((caseItem, index) => (
                        <div key={index} className="caselist-thread-item" onClick={() => handleThreadClick(caseItem)}>
                            <div className="caselist-thread-title">{removePdfExtension(caseItem.caseName)}</div>
                            <div className="caselist-thread-body">{`Case Type: ${caseItem.case_type || 'N/A'}`}</div>
                            <div className="caselist-thread-meta">
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{"width":"1.5%"}}>
                                    <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                </svg>
                                <span className='date-meta'>{caseItem.date}</span>
                            </div>
                        </div>
                    ))}
                    {filteredCases.length === 0 && !loading && (
                        <div className="no-results">No results found</div>
                    )}
                    {loading && <div>Loading...</div>}
                    <div id="sentinel" style={{ height: '1px' }}></div>
                </div>
            </div>
        </div>
    );
}

export default CaseList;
