import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/MyCase.css';
import Modal from 'react-modal';
import searchIcon from "../icons/search-icon.svg";
import hamburgerIcon from "../icons/hamburger-icon.svg";

const MyCase = ({ toggleMobileSidebar }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [caseName, setCaseName] = useState('');
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const caseItems = [
    {
      title: "A.Dhramraj v. The Cheif Educatinal officer, Puddukottai & ORS.",
      description: "Create JSON based on specified schema.",
      icon: "📄"
    },
    {
      title: "Adiraj Manpower services PVT. LTD. v. Commissioner of Central Excise Pune II",
      description: "Teach me a lesson on quadratic equations.",
      icon: "√"
    },
    {
      title: "Mongia Realty and Buildwell Private Limited v. Manik Sethi",
      description: "Create a set of elementary math worksheets for math educators and parents.",
      icon: "📊"
    },
    {
      title: "Geeta Devi v. State of U.P. & ORS.",
      description: "Create a curated list of scavenger hunt concepts.",
      icon: "🔍"
    },
    {
      title: "Adiraj Manpower services PVT. LTD. v. Commissioner of Central Excise Pune II",
      description: "Add unit tests for a Python function.",
      icon: "🕒"
    },
    {
      title: "Mongia Realty and Buildwell Private Limited v. Manik Sethi",
      description: "Convert unorganized text into structured tables.",
      icon: "🧳"
    }
  ];

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleFileChange = (event) => setFile(event.target.files[0]);

  // Asynchronous function to handle form submission and file upload
  const handleSubmit = async () => {
    if (!file || !caseName) {
      alert("Please provide both a case name and a file.");
      return;
    }

    setIsUploading(true); // Show loading state

    try {
      const formData = new FormData();
      formData.append("file", file);

      // API call to upload the file to the backend
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/mycase/upload_pdf`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { document_id, content_preview } = response.data;

      // Navigate to MyCaseUpload page with document_id and caseName
      navigate("/uploadmycase", {
        state: {
          documentId: document_id,
          caseName: caseName,
          contentPreview: content_preview,
        },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("There was an error uploading the file. Please try again.");
    } finally {
      setIsUploading(false);
      closeModal(); // Close modal after submission
    }
  };

  return (
    <div className="mycase-container">
      {/* My Case Title */}
      <div className="mycase-title">
      <div className='mycase-header-left'>
        <div><button className="mycase-mobile-hamburger" onClick={toggleMobileSidebar}>
          <img src={hamburgerIcon} alt="Hamburger Icon" />
        </button></div>
        <div><h1>My Case</h1></div>
        </div>
        <div className='mycase-header-right'>
          <h3 onClick={openModal} style={{ cursor: 'pointer' }}>Upload your case</h3>
        </div>
      </div>
      <div className="mycase-divider"></div>

      {/* Modal for Upload Case */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modal-content" overlayClassName="modal-overlay">
        <h2>Upload Case</h2>
        <form className="modal-form">
          <label>Case Name:</label>
          <input
            type="text"
            value={caseName}
            onChange={(e) => setCaseName(e.target.value)}
            required
          />

          <label>Attach Document:</label>
          <input
            type="file"
            onChange={handleFileChange}
            required
          />

          <div className="modal-buttons">
            <button
              type="button"
              onClick={handleSubmit}
              className="submit-btn"
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Submit"}
            </button>
            <button type="button" onClick={closeModal} className="close-btn">Close</button>
          </div>
        </form>
      </Modal>

      {/* Cards Title */}
      <div className="case-cards-title">
        <h1>Search My Cases</h1>

        <div className="mycase-search-bar">
          <div className="mycase-search-wrapper">
            <img className="mycase-search-icon" src={searchIcon} alt="" />
            <input type="text" placeholder="Search..." />
          </div>
        </div>
      </div>

      {/* Cards Section */}
      <div className="case-cards-container">
        {caseItems.map((item, index) => (
          <div key={index} className="case-card">
            <div className="case-content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyCase;