import React, { useState, useEffect } from 'react';
import "../css/CaseList.css";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import rightIcon from "../icons/right-icon.svg";

const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;
    
    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function DepartmentsList({ toggleMobileSidebar }) {
    const navigate = useNavigate();
    const [departments, setDepartments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchDepartments = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/departments`);
            if (response.data && Array.isArray(response.data.departments)) {
                setDepartments(response.data.departments);
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            console.error('Error fetching departments:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const filteredDepartments = departments.filter(department =>
        department.departmentName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleDepartmentClick = (departmentName) => {
        navigate('/departmentfiles', { state: { departmentName } });
    };
    

    return (
        <div className="caselist">
            <LoadingModal isLoading={isLoading} />

            <div className="caselist-header">
                <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src="../icons/hamburger-icon.svg" alt="Hamburger Icon" />
                </button>
                <button
                    onClick={() => navigate(-1)}
                    className="addcase-back-button"
                >
                    <img src={rightIcon} alt="" className="back-icon" />
                </button>
                <h3>Departments</h3>
            </div>

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search Departments"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="section-divider"></div>
            <div className="caselist-content" style={{ marginTop: "20px" }}>
                <div className="caselist-thread-section">
                    {filteredDepartments.map((department, index) => (
                        <div
                            key={index}
                            className="caselist-thread-item"
                            onClick={() => handleDepartmentClick(department.departmentName)}
                        >
                            <div className="caselist-thread-title">{department.departmentName}</div>
                            <div className="caselist-thread-body">Department information available.</div>
                        </div>
                    ))}

                    {filteredDepartments.length === 0 && !isLoading && (
                        <div className="no-results">
                            No results found
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DepartmentsList;
