import React, { createContext, useState } from 'react';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionData, setSessionData] = useState({
    token: localStorage.getItem('token') || null,
    user_id: localStorage.getItem('userId') || null,
    session_id: localStorage.getItem('sessionId') || null,
  });

  // Function to set session data after login and store in localStorage
  const setToken = (token) => {
    localStorage.setItem('token', token);
    setSessionData((prev) => ({ ...prev, token }));
  };

  const setUserId = (user_id) => {
    localStorage.setItem('userId', user_id);
    setSessionData((prev) => ({ ...prev, user_id }));
  };

  const setSessionId = (session_id) => {
    localStorage.setItem('sessionId', session_id);
    setSessionData((prev) => ({ ...prev, session_id }));
  };

  // Function to clear session data during logout
  const clearSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('sessionId');
    setSessionData({
      token: null,
      user_id: null,
      session_id: null,
    });
  };

  return (
    <SessionContext.Provider
      value={{
        sessionData,
        setToken,
        setUserId,
        setSessionId,
        clearSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
