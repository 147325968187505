import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/CaseList.css';
import rightIcon from '../icons/right-icon.svg';
import hamburgerIcon from '../icons/hamburger-icon.svg';

// Loading Modal Component
const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function MinistryFilesList({ toggleMobileSidebar }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { ministryName } = location.state || {};

    const [folders, setFolders] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state for the modal
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // Fetch folders for the selected ministry
        const fetchFolders = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_PROD_BACKEND_URL}/doc/ministries/${encodeURIComponent(ministryName)}/folders`
                );
                setFolders(response.data.folders || []);
            } catch (error) {
                console.error("Error fetching folders:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFolders();
    }, [ministryName]);

    // Function to handle folder click and navigate to the next page
    const handleFolderClick = (folderName) => {
        navigate('/ministryfolderfiles', { state: { folderName, ministryName } });
    };

    const removePdfExtension = (title) => {
        return title ? title.replace(/\.pdf$/i, '') : ''; // Ensure title is valid
    };

    const filteredFolders = folders.filter(folder =>
        removePdfExtension(folder.folderName).toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="caselist">
            <LoadingModal isLoading={isLoading} />

            <div className="caselist-header">
                <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button>
                <button onClick={() => navigate(-1)} className="addcase-back-button">
                    <img src={rightIcon} alt="" className="back-icon" />
                </button>
                <h3>{ministryName || "Ministry Files"}</h3>
            </div>

            <div className="section-divider"></div>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search Folders"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="caselist-content" style={{ marginTop: "50px" }}>
                {filteredFolders.map((folder, index) => (
                    <div
                        key={index}
                        className="caselist-thread-item"
                        onClick={() => handleFolderClick(folder.folderName)}
                    >
                        <div className="caselist-thread-title">{folder.folderName}</div>
                        {/* <div className="caselist-thread-body">Click to view files in this folder</div> */}
                    </div>
                ))}

                {filteredFolders.length === 0 && !isLoading && (
                    <div className="no-results">No folders found.</div>
                )}
            </div>
        </div>
    );
}

export default MinistryFilesList;
