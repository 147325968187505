import React, { useState, useEffect } from 'react';
import '../css/Settings.css';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import hamburgerIcon from "../icons/hamburger-icon.svg";
import axios from "axios"
import { Navigate, useNavigate } from 'react-router-dom';
import { Typography, Card, CardContent, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress, DialogContentText } from '@mui/material';

const SettingsPage = ({ toggleMobileSidebar }) => {
    const [value, setValue] = useState('1');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingPhone, setIsEditingPhone] = useState(false);
    const [isEditingCity, setIsEditingCity] = useState(false);
    const [isEditingFirm, setIsEditingFirm] = useState(false);
    const [isEditingProfession, setIsEditingProfession] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false); // State to manage update loading
    const navigate = useNavigate()

    // State to hold user data
    const [user, setUser] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        location: '',
        isLawyer: false,
        firm: ''
    });

    // State to hold previous values in case editing is canceled
    const [previousValue, setPreviousValue] = useState('');

    // Fetch user data when component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userId = localStorage.getItem('userId'); // Fetch the user ID from localStorage
                if (!userId) {
                    console.error('No user ID found in local storage');
                    return;
                }

                const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/user/${userId}`);
                setUser(response.data.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    // Handlers for state updates
    const handleNameChange = (e) => setUser({ ...user, name: e.target.value });
    const handlePhoneChange = (e) => setUser({ ...user, phoneNumber: e.target.value });
    const handleCityChange = (e) => setUser({ ...user, location: e.target.value });
    const handleFirmChange = (e) => setUser({ ...user, firm: e.target.value });
    const handleProfessionChange = (e) => setUser({ ...user, isLawyer: e.target.checked });

    // Save updates to the backend
    const saveUpdates = async (field, value) => {
        try {
            const userId = localStorage.getItem('userId'); // Fetch the user ID again for the PUT request
            if (!userId) {
                console.error('No user ID found in local storage');
                return;
            }

            setIsUpdating(true); // Set loading state to true before starting the update

            const response = await axios.put(`${process.env.REACT_APP_PROD_BACKEND_URL}/user/${userId}`, { [field]: value });

            setUser(response.data.data);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsUpdating(false); // Set loading state to false after update completes
        }
    };

    const handleSave = (field) => {
        const value = user[field];
        saveUpdates(field, value);
    };

    // Handle Enter and Escape key press for saving and canceling updates
    const handleKeyDown = (e, field) => {
        if (e.key === 'Enter') {
            handleSave(field);
            toggleEditState(field, false);
        } else if (e.key === 'Escape') {
            setUser({ ...user, [field]: previousValue }); // Revert to previous value
            toggleEditState(field, false);
        }
    };

    // Toggle edit states and set the previous value before editing
    const toggleEditState = (field, state) => {
        if (state) {
            setPreviousValue(user[field]); // Save the previous value before editing
        }

        switch (field) {
            case 'name':
                setIsEditingName(state);
                break;
            case 'phoneNumber':
                setIsEditingPhone(state);
                break;
            case 'location':
                setIsEditingCity(state);
                break;
            case 'firm':
                setIsEditingFirm(state);
                break;
            default:
                break;
        }
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleConfirmDelete = async () => {
        try {
            const userId = localStorage.getItem('userId'); // Fetch the user ID
            if (!userId) {
                console.error('No user ID found in local storage');
                return;
            }

            // Call the delete account API
            await axios.delete(`${process.env.REACT_APP_PROD_BACKEND_URL}/auth/delete-account/${userId}`);

            // Clear local storage and redirect to login page
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error('Error deleting account:', error);
        } finally {
            setOpenDeleteDialog(false);
        }
    };

    

    // Handle logout
    const handleLogout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_PROD_BACKEND_URL}/auth/logout`);
            localStorage.clear(); // Clear local storage
            navigate('/login'); // Redirect to login page
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <div className="settings-page">
            <TabContext value={value}>
                <Box className="settings-header">
                    <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                        <img src={hamburgerIcon} alt="Hamburger Icon" />
                    </button>
                    <Typography id="settings-heading">Manage Account</Typography>
                </Box>
                <TabPanel value="1">
                    <div id='parent-account-card'>
                        <Card className="account-card">
                            <CardContent>
                                <div id='Account-card'>
                                    <Typography>Name</Typography>
                                    <div className="flex-container">
                                        {isEditingName ? (
                                            <TextField
                                                value={user.name}
                                                onChange={handleNameChange}
                                                onBlur={() => {
                                                    handleSave('name');
                                                    toggleEditState('name', false);
                                                }}
                                                onKeyDown={(e) => handleKeyDown(e, 'name')}
                                                autoFocus
                                            />
                                        ) : (
                                            <>
                                                <Typography>{user.name}</Typography>
                                                <Button onClick={() => toggleEditState('name', true)}>Edit</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="divider-Settings"></div>
                                <div id='Account-card'>
                                    <Typography>Phone Number</Typography>
                                    <div className="flex-container">
                                        <Typography>{user.phoneNumber}</Typography>
                                    </div>
                                </div>
                                <div className="divider-Settings"></div>
                                <div id='Account-card'>
                                    <Typography>Email</Typography>
                                    <div className="flex-container">
                                        <Typography>{user.email}</Typography>
                                    </div>
                                </div>
                                <div className="divider-Settings"></div>
                                <div id='Account-card'>
                                    <Typography>City</Typography>
                                    <div className="flex-container">
                                        {isEditingCity ? (
                                            <TextField
                                                value={user.location}
                                                onChange={handleCityChange}
                                                onBlur={() => {
                                                    handleSave('location');
                                                    toggleEditState('location', false);
                                                }}
                                                onKeyDown={(e) => handleKeyDown(e, 'location')}
                                                autoFocus
                                            />
                                        ) : (
                                            <>
                                                <Typography>{user.location}</Typography>
                                                <Button onClick={() => toggleEditState('location', true)}>Edit</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="divider-Settings"></div>
                                <div id='Account-card'>
                                    <Typography>Is Lawyer</Typography>
                                    <div className="flex-container">
                                        {isEditingProfession ? (
                                            <input
                                                type="checkbox"
                                                checked={user.isLawyer}
                                                onChange={handleProfessionChange}
                                                onBlur={() => {
                                                    handleSave('isLawyer');
                                                    toggleEditState('isLawyer', false);
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <Typography>{user.isLawyer ? "Yes" : "No"}</Typography>
                                                <Button onClick={() => toggleEditState('isLawyer', true)}>Edit</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="divider-Settings"></div>
                                <div id='Account-card'>
                                    <Typography>Firm</Typography>
                                    <div className="flex-container">
                                        {isEditingFirm ? (
                                            <TextField
                                                value={user.firm}
                                                onChange={handleFirmChange}
                                                onBlur={() => {
                                                    handleSave('firm');
                                                    toggleEditState('firm', false);
                                                }}
                                                onKeyDown={(e) => handleKeyDown(e, 'firm')}
                                                autoFocus
                                            />
                                        ) : (
                                            <>
                                                <Typography>{user.firm}</Typography>
                                                <Button onClick={() => toggleEditState('firm', true)}>Edit</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="divider-Settings"></div>
                    <div id='parent-system-card'>
                        <Card>
                            <CardContent>
                                <div id='Account-card'>
                                    <span>
                                        <Typography>Sign out</Typography>
                                        <Typography className="session-info">Logout from Karma AI</Typography>
                                    </span>
                                    <div id='sign-out-all-device'>
                                        <button onClick={handleLogout}>Logout</button>
                                    </div>
                                </div>
                                <div className="divider-Settings"></div>
                                <div id='Account-card'>
                                    <span>
                                        <Typography>Delete account</Typography>
                                        <Typography className="delete-info">Permanently delete your account and data</Typography>
                                    </span>
                                    <span id='learn-more'>
                                        <Button variant="contained" color="black" onClick={handleOpenDeleteDialog}>
                                            Delete Account
                                        </Button>
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </TabPanel>
                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                    <DialogTitle>Confirm Account Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='deletemessage'>
                            Are you sure you want to permanently delete your account? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id='deletemessage' onClick={handleCloseDeleteDialog} color="primary">No, I don't want to delete</Button>
                        <Button id='deletemessage' onClick={handleConfirmDelete} color="error">Yes, I want to Delete</Button>
                    </DialogActions>
                </Dialog>

                {/* Loading Overlay */}
                {isUpdating && (
                    <div className="modal-overlay">
                        <div className="modal-box">
                            <CircularProgress />
                            <p>Updating data, please wait...</p>
                        </div>
                    </div>
                )}
            </TabContext>
        </div>
    );
};

export default SettingsPage;
