import React, { useState, useEffect, useRef } from 'react';
import "../css/CaseList.css";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import hamburgerIcon from "../icons/hamburger-icon.svg";
import rightIcon from "../icons/right-icon.svg";
import searchIcon from "../icons/search-icon.svg";

// Loading Modal Component
const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function MinistryFolderList({ toggleMobileSidebar }) {
    const navigate = useNavigate();

    const [ministries, setMinistries] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [cursor, setCursor] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false); // Loading state
    const [isModalLoading, setIsModalLoading] = useState(false); // Loading state for the modal
    const observer = useRef();

    useEffect(() => {
        fetchMinistries();
    }, []);

    useEffect(() => {
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                fetchMinistries();
            }
        });

        const sentinel = document.querySelector('#sentinel');
        if (sentinel) observer.current.observe(sentinel);

        return () => {
            if (sentinel) observer.current.unobserve(sentinel);
        };
    }, [hasMore, loading]);

    const fetchMinistries = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_PROD_BACKEND_URL}/doc/ministries`, {
                params: {
                    cursor: cursor,
                    limit: 10,
                },
            });

            if (response.data && Array.isArray(response.data.ministries)) {
                if (response.data.ministries.length > 0) {
                    setMinistries((prevMinistries) => [...prevMinistries, ...response.data.ministries]);
                    setCursor(response.data.cursor);
                    setHasMore(response.data.cursor !== null);
                } else {
                    setHasMore(false);
                }
            } else {
                console.error('Unexpected response structure:', response.data);
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching ministries:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleMinistryClick = (ministryName) => {
        setIsModalLoading(true); // Show loading modal
        try {

            navigate('/ministryfiles', { state: { ministryName } });


        } catch (error) {
            console.error('Error navigating to ministry folders:', error);
        } finally {
            setIsModalLoading(false); // Hide loading modal
        }
    };

    const filteredMinistries = ministries.filter(ministry =>
        ministry.ministryName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="caselist">
            <LoadingModal isLoading={isModalLoading} /> {/* Show loading modal when a ministry is clicked */}

            <div className="caselist-header">
                <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button>
                <button
                    onClick={() => navigate(-1)}
                    className="addcase-back-button"
                >
                    <img src={rightIcon} alt="" className="back-icon" />
                </button>
                <h3>Ministries</h3>
            </div>

            <div className="section-divider"></div>

            <div className="casefinder-search-bar">
                <div className="casefinder-search-wrapper">
                    <img className="casefinder-search-icon" src={searchIcon} alt="" />
                    <input
                        type="text"
                        placeholder="Search Ministries"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className="caselist-content">
                <div className="caselist-thread-section">
                    {filteredMinistries.map((ministry, index) => (
                        <div
                            key={index}
                            className="caselist-thread-item"
                            onClick={() => handleMinistryClick(ministry.ministryName)}
                        >
                            <div className="caselist-thread-title">{ministry.ministryName}</div>
                            <div className="caselist-thread-body">Click to view folders in {ministry.ministryName}</div>
                        </div>
                    ))}

                    {filteredMinistries.length === 0 && !loading && (
                        <div className="no-results">
                            No results found
                        </div>
                    )}

                    {loading && <div>Loading...</div>}
                    <div id="sentinel" style={{ height: '1px' }}></div>
                </div>
            </div>
        </div>
    );
}

export default MinistryFolderList;
