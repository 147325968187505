import React, { useState, useEffect, useRef } from "react";
import "../css/Dropdown.css";
import threedotsIcon from "../icons/three-dots-icon.svg";

const ThreadOptionsDropdown = ({ onArchive, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (event) => {
    event.stopPropagation(); // Prevents event bubbling up to parent components
    setIsOpen((prevState) => !prevState);
  };

  const handleOptionClick = (option) => {
    // Calls the appropriate function passed from the parent component
    if (option === "Archive" && onArchive) {
      onArchive();
    } else if (option === "Delete" && onDelete) {
      onDelete();
    }
    setIsOpen(false); // Close the dropdown after selecting an option
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="thread-dropdown" ref={dropdownRef}>
      <button className="dropdown-button" onClick={toggleDropdown}>
        <img className="dropdown-thread-icon" src={threedotsIcon} alt="Options" />
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => handleOptionClick("Archive")}>
            Archive
          </div>
          <div className="dropdown-item" onClick={() => handleOptionClick("Delete")}>
            Delete
          </div>
        </div>
      )}
    </div>
  );
};

export default ThreadOptionsDropdown;
