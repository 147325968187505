import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/CaseList.css';
import rightIcon from '../icons/right-icon.svg';
import hamburgerIcon from '../icons/hamburger-icon.svg';

// Loading Modal Component
const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function MinistryFolderFilesList({ toggleMobileSidebar }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { ministryName, folderName } = location.state || {};

    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state for the modal
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // Fetch files for the selected folder
        const fetchFiles = async () => {
            if (!ministryName || !folderName) {
                console.error("Ministry name or folder name is missing");
                return;
            }
            setIsLoading(true);
            try {
                // Updated API call to include folderName
                const response = await axios.get(
                    `${process.env.REACT_APP_PROD_BACKEND_URL}/doc/ministries/${encodeURIComponent(ministryName)}/${encodeURIComponent(folderName)}/files`
                );
                setFiles(response.data.ministryFiles || []);
            } catch (error) {
                console.error("Error fetching files:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFiles();
    }, [ministryName, folderName]);

    const removePdfExtension = (title) => {
        return title ? title.replace(/\.pdf$/i, '') : ''; // Ensure title is valid
    };

    const filteredFiles = files.filter(file =>
        removePdfExtension(file.fileName).toLowerCase().includes(searchQuery.toLowerCase())
    );


    const handleFileClick = async (url, fileName) => {
        setIsLoading(true); // Show loading modal
        try {
            // Fetch the PDF from the provided URL
            const pdfResponse = await axios.get(url, { responseType: 'blob' });
            if (!pdfResponse || !pdfResponse.data) {
                console.error('Failed to download the PDF from the provided URL');
                return;
            }

            // Prepare the file for upload
            const formData = new FormData();
            formData.append('file', new File([pdfResponse.data], `${fileName}.pdf`, { type: 'application/pdf' }));

            // Upload the PDF and trigger processing
            const uploadResponse = await axios.post(`${process.env.REACT_APP_PROD_AI_URL}/chat/upload_act_pdf`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (uploadResponse.status === 200) {
                const { act_document_id } = uploadResponse.data;

                // Save the document ID to localStorage
                localStorage.setItem('act_document_id', act_document_id);
                console.log('File uploaded and processing triggered:', uploadResponse.data.message);

                // Navigate to AddMinistry page
                navigate('/addministry', {
                    state: { documentId: act_document_id, fileName, fileUrl: url }
                });
            } else {
                console.error('Error uploading the file:', uploadResponse.data.detail);
            }
        } catch (error) {
            console.error('Error processing PDF:', error);
        } finally {
            setIsLoading(false); // Hide loading modal
        }
    };


    return (
        <div className="caselist">
            <LoadingModal isLoading={isLoading} />

            <div className="caselist-header">
                <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button>
                <button onClick={() => navigate(-1)} className="addcase-back-button">
                    <img src={rightIcon} alt="" className="back-icon" />
                </button>
                <h3>{folderName || "Folder Files"}</h3>
            </div>

            <div className="section-divider"></div>
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search Files"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="caselist-content" style={{ marginTop: "60px" }}>
                {filteredFiles.map((file, index) => (
                    <div
                        key={index}
                        className="caselist-thread-item"
                        onClick={() => handleFileClick(file.url, file.fileName)} // Pass URL and file name
                    >
                        <div className="caselist-thread-title">{removePdfExtension(file.fileName)}</div>
                    </div>
                ))}

                {filteredFiles.length === 0 && !isLoading && (
                    <div className="no-results">No files found.</div>
                )}
            </div>

        </div>
    );
}

export default MinistryFolderFilesList;
