import React, { useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import MainSection from "./components/MainContent";
import CaseFinder from "./components/CaseFinder";
import RecentChats from "./components/RecentChats";
import SettingsPage from "./components/Settings";
import CaseList from "./components/CaseList";
import AddCase from "./components/AddCase";
import ActsFinder from "./components/ActsFinder";
import ActsList from "./components/ActsList";
import AddActs from "./components/AddActs";
import Mycase from "./components/MyCase";
import Register from "./components/Register";
import Login from "./components/Login";
import KarmaDocs from "./components/KarmaDocs";
import ArchiveChat from "./components/ArchiveChat";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import LandmarkList from "./components/LandmarkList";
import MyCaseUpload from "./components/MyCaseUpload";
import StateSelection from "./components/StateSelection";
import SupremecourtYearList from "./components/SupremecourtYearList";
import BillsList from "./components/BillsList";
import AddBills from "./components/AddBills";
import DepartmentsList from "./components/DepartmentList";
import DepartmentFilesList from "./components/DepartmentFilesList";
import MinistryFolderList from "./components/MinistryFolderList";
import MinistryFilesList from "./components/MinistryFilesList";
import { SessionProvider, SessionContext } from "./components/SessionContext";
import { ChatProvider } from "./components/ChatContext";
import "./App.css";
import AddDepartments from "./components/AddDepartments";
import MinistryFolderFilesList from "./components/MinistryFolderFilesList";
import AddMinistry from "./components/AddMinistry";
import ConstitutionList from "./components/ConstitutionList";
import AddConstitution from "./components/AddConstitution";

// ProtectedRoute component to protect routes
const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { sessionData, loading } = useContext(SessionContext);

  if (loading) {
    return <div>Loading...</div>; // Show loading until session data is fetched
  }

  // If no token, redirect to login page
  return sessionData?.token ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" replace />
  );
};

function App() {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const location = useLocation();

  // Function to toggle sidebar visibility
  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  // Determine whether to show the sidebar based on the current route
  const shouldShowSidebar =
    location.pathname !== "/login" &&
    location.pathname !== "/register" &&
    location.pathname !== "/resetpass" &&
    location.pathname !== "/forgotpass";

  return (
    <SessionProvider>
      <ChatProvider>
        <div className="app-layout">
          {shouldShowSidebar && (
            <>
              <Sidebar
                isMobileVisible={isMobileSidebarOpen}
                toggleMobileSidebar={toggleMobileSidebar}
              />
              {isMobileSidebarOpen && (
                <div
                  className={`overlay ${
                    isMobileSidebarOpen ? "active" : "hidden"
                  }`}
                  onClick={toggleMobileSidebar}
                ></div>
              )}
            </>
          )}

          <div className="main-content">
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route
                path="/home"
                element={
                  <ProtectedRoute
                    element={MainSection}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/casefinder"
                element={
                  <ProtectedRoute
                    element={CaseFinder}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/selectState"
                element={
                  <ProtectedRoute
                    element={StateSelection}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/resetpass" element={<ResetPassword />} />
              <Route path="/forgotpass" element={<ForgotPassword />} />
              <Route
                path="/uploadmycase"
                element={
                  <ProtectedRoute
                    element={MyCaseUpload}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/caselist"
                element={
                  <ProtectedRoute
                    element={CaseList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/addcase"
                element={
                  <ProtectedRoute
                    element={AddCase}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/actsfinder"
                element={
                  <ProtectedRoute
                    element={ActsFinder}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/landmarklist"
                element={
                  <ProtectedRoute
                    element={LandmarkList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/actslist"
                element={
                  <ProtectedRoute
                    element={ActsList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/billslist"
                element={
                  <ProtectedRoute
                    element={BillsList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />
              <Route
                path="/ministrylist"
                element={
                  <ProtectedRoute
                    element={MinistryFolderList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/ministryfiles"
                element={
                  <ProtectedRoute
                    element={MinistryFilesList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/ministryfolderfiles"
                element={
                  <ProtectedRoute
                    element={MinistryFolderFilesList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/addministry"
                element={
                  <ProtectedRoute
                    element={AddMinistry}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/departments"
                element={
                  <ProtectedRoute
                    element={DepartmentsList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/departmentfiles"
                element={
                  <ProtectedRoute
                    element={DepartmentFilesList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/addDepartments"
                element={
                  <ProtectedRoute
                    element={AddDepartments}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/addbills"
                element={
                  <ProtectedRoute
                    element={AddBills}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/yearlist"
                element={
                  <ProtectedRoute
                    element={SupremecourtYearList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/addacts"
                element={
                  <ProtectedRoute
                    element={AddActs}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/recentchats"
                element={
                  <ProtectedRoute
                    element={RecentChats}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/mycase"
                element={
                  <ProtectedRoute
                    element={Mycase}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/constitutionlist"
                element={
                  <ProtectedRoute
                    element={ConstitutionList}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/addconstituition"
                element={
                  <ProtectedRoute
                    element={AddConstitution}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/settings"
                element={
                  <ProtectedRoute
                    element={SettingsPage}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/karmadocs"
                element={
                  <ProtectedRoute
                    element={KarmaDocs}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />

              <Route
                path="/archive"
                element={
                  <ProtectedRoute
                    element={ArchiveChat}
                    toggleMobileSidebar={toggleMobileSidebar}
                    isMobileSidebarOpen={isMobileSidebarOpen}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </ChatProvider>
    </SessionProvider>
  );
}

export default App;
