import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { SessionContext } from "./SessionContext"; // Import context
import "../css/Login.css";
import fullLogo from "../icons/finalogobil.png"

const productionFastAPI = axios.create({
  baseURL: 'https://project-karma-ai-448523777263.us-central1.run.app',
  headers: {
      'Content-Type': 'application/json',
  },
});

const productionNode = axios.create({
  baseURL: 'https://project-karma-be-448523777263.us-central1.run.app',
  headers: {
      'Content-Type': 'application/json',
  },
});

const Login = () => {
  const { setToken, setUserId, setSessionId } = useContext(SessionContext); // Use session context to set session after login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state
  const navigate = useNavigate();

  useEffect(() => {
    // Check if session data is already in sessionStorage when the component loads
    const storedToken = sessionStorage.getItem("token");
    const storedUserId = sessionStorage.getItem("userId");
    const storedSessionId = sessionStorage.getItem("sessionId");

    if (storedToken && storedUserId && storedSessionId) {
      // If the session data is available, update the context
      setToken(storedToken);
      setUserId(storedUserId);
      setSessionId(storedSessionId);
      navigate("/home"); // Navigate to home if user is already logged in
    }
  }, [setToken, setUserId, setSessionId, navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prev) => ({ ...prev, email: "Invalid email address" }));
    } else {
      setErrors((prev) => ({ ...prev, email: null }));
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
  
    if (!email || !password) {
      setErrors((prev) => ({ ...prev, form: "Both fields are required" }));
      return;
    }
  
    if (Object.values(errors).some((error) => error !== null)) {
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_PROD_BACKEND_URL}/auth/login`, {
        email,
        password,
      });
  
      const { token, session_id, user } = response.data;
  
      // Set the token, user ID, and session ID in context after successful login
      setToken(token);
      setUserId(user.id);
      setSessionId(session_id);
  
      // Also store the session data in localStorage for later retrieval
      localStorage.setItem("token", token);
      localStorage.setItem("userId", user.id);
      localStorage.setItem("sessionId", session_id);
  
      // Clear mainSection-related data from localStorage to avoid clashes
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("mainSection_")) {
          localStorage.removeItem(key);
        }
      });
  
      // Redirect to the home page
      navigate("/home");
    } catch (error) {
      console.error(
        "Error logging in:",
        error.response?.data?.message || error.message || error
      );
      setErrors((prev) => ({
        ...prev,
        form: error.response?.data?.message || "Failed to log in. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle input changes and reset errors
  const handleInputChange = (setter, fieldName) => (e) => {
    setter(e.target.value);
    setErrors((prev) => ({ ...prev, [fieldName]: null, form: null })); // Reset errors for the field and form
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <form className="login-form" onSubmit={handleLogin}>
          <h2>Login</h2>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={handleInputChange(setEmail, "email")}
            />
            {errors.email && <p className="error-text">{errors.email}</p>}
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="password-container">
              <input
                type={passwordVisibility ? "text" : "password"}
                value={password}
                onChange={handleInputChange(setPassword, "password")}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="toggle-password-btn"
              >
                {passwordVisibility ? "Hide" : "Show"}
              </button>
            </div>
          </div>
          {errors.form && <p className="error-text">{errors.form}</p>}
          <button type="submit" className="login-button">
            Submit
          </button>
          <div className="login-links">
            <Link to="/forgotpass" className="forgot-password-link">
              Forgot Password?
            </Link>
            <Link to="/register" className="register-link">
              Don't have an account? Register
            </Link>
          </div>
        </form>
      </div>

      <div className="login-right">
        <div className="covering">
          <img className="karma-full-logo" src={fullLogo} alt="" />
        </div>
      </div>

      {/* Modal Box */}
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-box">
            <div className="spinner"></div>
            <p>Logging in, please wait...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
