import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../css/ForgotPassword.css";
import backIcon from "../icons/right-icon.svg";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prev) => ({ ...prev, email: "Invalid email address" }));
    } else {
      setErrors((prev) => ({ ...prev, email: null }));
    }
  };

  const handleInputChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
    // Reset the form errors when the user starts typing
    setErrors((prev) => ({ ...prev, form: null }));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!email) {
      setErrors((prev) => ({ ...prev, email: "Email is required" }));
      return;
    }

    if (errors.email) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_PROD_BACKEND_URL}/auth/forgot-password`, {
        email,
      });

      setMessage(response.data.message);
      setTimeout(() => {
        navigate("/login");
      }, 3000); // Redirect to login page after 3 seconds
    } catch (error) {
      console.error(
        "Error sending password reset email:",
        error.response?.data?.message || error.message
      );
      setErrors((prev) => ({
        ...prev,
        email: error.response?.data?.message || "Failed to send reset email.",
      }));
    }
  };

  return (
    <div className="forgot-password-container">
      {/* Back Button */}
      

      <form className="forgot-password-form" onSubmit={handleForgotPassword}>
      <button className="fp-back-button" onClick={() => navigate(-1)}>
        <img src={backIcon} alt="" />
      </button>
        <h2>Forgot Password</h2>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={handleInputChange}
          />
          {/* Instruction span */}
          
          {errors.email && <p className="error-text">{errors.email}</p>}
        </div>
        {message && <p className="success-text">{message}</p>}
        <button type="submit" className="forgot-password-button">
          Send Reset Link
        </button>
        <span className="instruction-text">
            Please enter the email associated with your account.
          </span>
      </form>
    </div>
  );
};

export default ForgotPassword;
