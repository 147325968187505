import React from "react";
import "../css/CaseList.css";
import "../css/SupremecourtYearList.css";

const SupremecourtCourtList = () => {

    const courts = [
        {
            name: "Andhra Pradesh High Court",
            state: "Andhra Pradesh",
            location: "Amaravati"
        },
        {
            name: "The Gauhati High Court",
            state: "Arunachal Pradesh + Assam + Mizoram + Nagaland",
            location: "Guwahati",
            benches: [
                { name: "Kohima Bench", state: "State of Nagaland" },
                { name: "Aizwal Bench", state: "Mizoram" },
                { name: "Itanagar Bench", state: "Arunachal Pradesh" }
            ]
        },
        {
            name: "The High Court of Judicature at Patna",
            state: "Bihar",
            location: "Patna",
            benches: [
                { name: "Ranchi Bench", state: "Jharkhand (Permanent)" }
            ]
        },
        {
            name: "The High Court of Bilaspur",
            state: "Chhattisgarh",
            location: "Bilaspur"
        },
        {
            name: "High Court of Bombay at Goa",
            state: "Goa",
            location: "Mumbai"
        },
        {
            name: "The Gujarat High Court",
            state: "Gujarat",
            location: "Ahmedabad",
            benches: [
                { name: "Surat Bench", state: "Permanent" },
                { name: "Ahmedabad Bench", state: "" }
            ]
        },
        {
            name: "Punjab and Haryana High Court",
            state: "Haryana + Punjab + Chandigarh",
            location: "Chandigarh"
        },
        {
            name: "High Court of Himachal Pradesh",
            state: "Himachal Pradesh",
            location: "Shimla"
        },
        {
            name: "High Court of Jharkhand",
            state: "Jharkhand",
            location: "Ranchi"
        },
        {
            name: "The High Court of Karnataka",
            state: "Karnataka",
            location: "Bengaluru",
            benches: [
                { name: "Bengaluru Bench", state: "" },
                { name: "Dharwad Bench", state: "" },
                { name: "Kalaburagi Bench", state: "" }
            ]
        },
        {
            name: "High Court of Kerala",
            state: "Kerala + Union Territory of Lakshadweep",
            location: "Ernakulam",
            benches: [
                { name: "Ernakulam Bench", state: "" },
                { name: "Thiruvananthapuram Bench", state: "" }
            ]
        },
        {
            name: "High Court of Madhya Pradesh",
            state: "Madhya Pradesh",
            location: "Jabalpur",
            benches: [
                { name: "Indore Bench", state: "" },
                { name: "Gwalior Bench", state: "" }
            ]
        },
        {
            name: "The Bombay High Court",
            state: "Maharashtra + Dadra and Nagar Haveli and Daman and Diu",
            location: "Mumbai",
            benches: [
                { name: "Nagpur Bench", state: "" },
                { name: "Aurangabad Bench", state: "" },
                { name: "Panaji Bench", state: "" }
            ]
        },
        {
            name: "The High Court of Manipur at Imphal",
            state: "Manipur",
            location: "Imphal"
        },
        {
            name: "High Court of Meghalaya",
            state: "Meghalaya",
            location: "Shillong"
        },
        {
            name: "Orissa High Court",
            state: "Odisha",
            location: "Cuttack"
        },
        {
            name: "Rajasthan High Court",
            state: "Rajasthan",
            location: "Jodhpur",
            benches: [
                { name: "Jaipur Bench", state: "" }
            ]
        },
        {
            name: "High Court of Sikkim",
            state: "Sikkim",
            location: "Gangtok"
        },
        {
            name: "The High Court of Judicature at Madras",
            state: "Tamil Nadu + Puducherry",
            location: "Chennai",
            benches: [
                { name: "Madurai Bench", state: "" }
            ]
        },
        {
            name: "High Court for the State of Telangana",
            state: "Telangana",
            location: "Hyderabad"
        },
        {
            name: "High Court of Tripura",
            state: "Tripura",
            location: "Agartala"
        },
        {
            name: "High Court of Judicature at Allahabad",
            state: "Uttar Pradesh",
            location: "Prayagraj",
            benches: [
                { name: "Lucknow Bench", state: "" }
            ]
        },
        {
            name: "High Court of Uttarakhand",
            state: "Uttarakhand",
            location: "Nainital"
        },
        {
            name: "The Calcutta High Court",
            state: "West Bengal + Andaman and Nicobar Islands",
            location: "Kolkata",
            benches: [
                { name: "Jalpaiguri Circuit Bench", state: "" },
                { name: "Port Blair Circuit Bench", state: "" }
            ]
        },
        {
            name: "High Court of Delhi",
            state: "The National Capital Territory of Delhi",
            location: ""
        },
        {
            name: "High Court of Jammu and Kashmir and Ladakh",
            state: "Jammu and Kashmir + Ladakh",
            location: ""
        }
    ];
    

    return (
        <div className="court-list">
            <div className="courtlist-header">
                <h1>High Courts of India</h1>
            </div>
        <div className="section-divider"></div>
        {courts.map((court, index) => (
            <div key={index} className="court-card">
                <h3 className="court-name">{court.name}</h3>
                <p><strong>State(s):</strong> {court.state || "Not Available"}</p>
                <p><strong>Location:</strong> {court.location || "Not Available"}</p>
                {court.benches && (
                    <div className="court-benches">
                        <p><strong>Benches:</strong></p>
                        {court.benches.map((bench, idx) => (
                            <div key={idx} className="court-bench">
                                <p><strong>{bench.name}</strong> - {bench.state || "Not Available"}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ))}
    </div>
    );
};

export default SupremecourtCourtList;
