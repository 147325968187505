import React, { useState, useEffect, useRef } from "react";
import "../css/AddCase.css";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useNavigate, useLocation } from "react-router-dom";
import attachIcon from "../icons/attach-icon.svg";
import rightIcon from "../icons/right-icon.svg";

function AddCase() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    documentId: initialDocumentId,
    caseName,
    pdfUrl,
    case_type,
    date,
    judges,
    citation,
  } = location.state || {};
  const [documentId, setDocumentId] = useState(initialDocumentId);
  const [activeAnalysisItem, setActiveAnalysisItem] =
    useState("Original Document");
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [attachDropdownOpen, setAttachDropdownOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const chatDisplayRef = useRef(null);

  useEffect(() => {
    const storedDocumentId = localStorage.getItem("case_document_id");
    if (storedDocumentId && !documentId) {
      setDocumentId(storedDocumentId);
    }

    if (pdfUrl) {
      fetchPdfBlob(pdfUrl);
    }
  }, [pdfUrl]);

  const fetchPdfBlob = async (url) => {
    try {
      const pdfResponse = await axios.get(url, { responseType: "blob" });
      if (pdfResponse.data) {
        const blobUrl = URL.createObjectURL(pdfResponse.data);
        setPdfBlobUrl(blobUrl);
      } else {
        console.error("Failed to fetch the PDF blob from the provided URL");
      }
    } catch (error) {
      console.error("Error fetching PDF blob:", error);
    }
  };

  useEffect(() => {
    if (documentId && activeAnalysisItem) {
      fetchTabData();
    }
  }, [documentId, activeAnalysisItem]);

  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [chatMessages, isChatLoading]);

  const fetchTabData = async () => {
    console.log(
      "Fetching data for document ID:",
      localStorage.getItem("case_document_id"),
      "with option:",
      activeAnalysisItem
    );

    try {
      const optionMap = {
        "Case Summary": "Case Summary",
        "Original Document": "Original Document",
        "Petitioner's Argument": "Petitioner's Argument",
        "Respondent's Argument": "Respondent's Argument",
        Citations: "Citations",
        Decision: "Decision",
      };

      const option = optionMap[activeAnalysisItem];
      if (!option) return;

      const documentId = localStorage.getItem("case_document_id");
      if (!documentId) {
        console.error(
          "Document ID not found. Please upload the document first."
        );
        return;
      }

      // Delay setting `isLoading` to true for 100ms
      const loadingTimeout = setTimeout(() => {
        setIsLoading(true);
      }, 100);

      const response = await axios.get(
        `${process.env.REACT_APP_PROD_AI_URL}/chat/case/${documentId}/content`,
        {
          params: { option },
        }
      );

      // Set data immediately after receiving response
      setData(response.data.content);

      // Clear loading timeout to prevent spinner if response was quick
      clearTimeout(loadingTimeout);
      setIsLoading(false); // Ensure loading is false as soon as data is set

      console.log("Cached response data:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData("Error fetching content. Please try again.");
    } finally {
      // Ensure loading is false in case of error
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendClick = async () => {
    if (!inputValue.trim()) return;

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const sessionId = localStorage.getItem("sessionId"); // Retrieve session_id from local storage
    const caseDocumentId =
      documentId || localStorage.getItem("case_document_id");

    if (!token || !userId || !sessionId || !caseDocumentId) {
      console.error(
        "Error: Missing token, user ID, session ID, or case document ID."
      );
      return;
    }

    // Add user message to chat
    const newMessage = { sender: "user", content: inputValue };
    setChatMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputValue("");

    try {
      setIsChatLoading(true);

      // Use FormData to handle the request as multipart/form-data
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("session_id", sessionId); // Add session_id to form data
      formData.append("chat_id", localStorage.getItem("chatId") || "");
      formData.append("case_document_id", caseDocumentId);
      formData.append("query", newMessage.content); // Add user input as query

      // Call the API
      const response = await axios.post(
        `${process.env.REACT_APP_PROD_AI_URL}/chat/file_chat_process`,
        formData
      );

      // Process and display the assistant's response
      const aiResponseContent = response.data.initial_response;
      const aiResponse = { sender: "assistant", content: aiResponseContent };
      setChatMessages((prevMessages) => [...prevMessages, aiResponse]);
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response?.data || error.message
      );
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "assistant",
          content: "An error occurred. Please try again.",
        },
      ]);
    } finally {
      setIsChatLoading(false);
    }
  };
  const toggleAttachDropdown = () => {
    setAttachDropdownOpen(!attachDropdownOpen);
  };

  const handleAnalysisItemClick = (item) => {
    setActiveAnalysisItem(item);
  };

  // Handle Enter key press for sending message
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent new line in the input
      handleSendClick();
    }
  };

  const renderMainContent = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }
    switch (activeAnalysisItem) {
      case "Case Summary":
        return (
          <div className="markdown-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {data || "No content available"}
            </ReactMarkdown>
          </div>
        );
      case "Original Document":
        return pdfUrl ? (
          <iframe
            src={pdfUrl}
            className="addcase-doc"
            title="PDF Document"
            style={{ width: "100%", height: "500px", border: "none" }}
          ></iframe>
        ) : (
          <p>No document available</p>
        );
      case "Citations":
        return (
          <div className="markdown-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {data || "Citations will be displayed here."}
            </ReactMarkdown>
          </div>
        );
      case "Petitioner's Argument":
        return (
          <div className="markdown-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {data || "Petitioner arguments go here."}
            </ReactMarkdown>
          </div>
        );
      case "Respondent's Argument":
        return (
          <div className="markdown-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {data || "Respondent arguments go here."}
            </ReactMarkdown>
          </div>
        );
      case "Decision":
        return (
          <div className="markdown-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {data || "Decision details will be shown here."}
            </ReactMarkdown>
          </div>
        );
      default:
        return <p>Select an analysis section to view content.</p>;
    }
  };

  return (
    <div className="addcase">
      <div className="addcase-header">
        <button
          onClick={() => navigate(-1)}
          className="addcase-back-button"
        >
          <img src={rightIcon} alt="" className="back-icon" />
        </button>
        <div className="addcase-title">
          <h2>{caseName || "Case Judgement"}</h2>
          <span>{case_type || "Case Type"}, </span>
          <span>{date || "Date"}, </span>
          <span>{judges || "Judges"}, </span>
          <span>{citation || "Citation"}</span>
        </div>

        <div className="analysis-buttons">
          {/* <button className="addcase-header-button">Case Summary</button> */}
          <button
            className="addcase-header-button"
            onClick={() => window.open(pdfUrl, "_blank")}
          >
            Original Source
          </button>
          <button
            className="addcase-header-button"
            onClick={() => window.open(pdfUrl)}
          >
            Download Pdf
          </button>
        </div>
      </div>
      <div className="addcase-analysis-section">
        <div className="addcase-analysis-header">
          {[
            "Case Summary",
            "Original Document",
            "Petitioner's Argument",
            "Respondent's Argument",
            "Citations",
            "Decision",
          ].map((item, index) => (
            <div
              key={index}
              className={`analysis-item ${
                activeAnalysisItem === item ? "active" : ""
              }`}
              onClick={() => handleAnalysisItemClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="addcase-main-content">
          <div className="addcase-analysis-document">{renderMainContent()}</div>
          <div className="addcase-chat-section">
            <h3 className="addcase-chat-title">Ask Karma</h3>
            {/* <div className="addcase-divider"></div> */}
            <div className="addcase-chat-display" ref={chatDisplayRef}>
              {chatMessages.map((msg, index) => (
                <div key={index} className={`chat-message ${msg.sender}`}>
                  <div className={`message-bubble ${msg.sender}`}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {msg.content}
                    </ReactMarkdown>
                  </div>
                </div>
              ))}
              {isChatLoading && (
                <div className="loading-indicator">Karma AI is typing...</div>
              )}
            </div>
            <div className="addcase-chat-prompt">
              <div className="chatbox-input-wrapper">
                <input
                  type="text"
                  className="chatbox-input"
                  placeholder="Type something"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown} // Add the Enter key event here
                />
                <div className="chatbox-buttons">
                  <button className="chatbox-send" onClick={handleSendClick}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCase;
