import React, { useState, useEffect, useRef } from "react";
import "../css/AddCase.css";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useNavigate, useLocation } from "react-router-dom";
import rightIcon from "../icons/right-icon.svg";

function AddConstitution() {
  const navigate = useNavigate();
  const location = useLocation();
  const { documentId: initialDocumentId, fileName, pdfUrl } = location.state || {};

  const [documentId, setDocumentId] = useState(initialDocumentId);
  const [activeAnalysisItem, setActiveAnalysisItem] = useState("Original PDF");
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(""); // State to store the fetched PDF blob URL
  const chatDisplayRef = useRef(null);

  useEffect(() => {
    const storedDocumentId = localStorage.getItem("act_document_id");
    if (storedDocumentId && !documentId) {
      setDocumentId(storedDocumentId);
    }

    if (pdfUrl) {
      fetchPdfBlob(pdfUrl); // Fetch the PDF blob
    }
  }, [pdfUrl]);

  const fetchPdfBlob = async (url) => {
    try {
      const pdfResponse = await axios.get(url, { responseType: "blob" });
      if (pdfResponse.data) {
        const blobUrl = URL.createObjectURL(pdfResponse.data);
        setPdfBlobUrl(blobUrl);
      } else {
        console.error("Failed to fetch the PDF blob from the provided URL");
      }
    } catch (error) {
      console.error("Error fetching PDF blob:", error);
    }
  };

  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [chatMessages, isChatLoading]);

  useEffect(() => {
    if (documentId && activeAnalysisItem) {
      fetchTabData();
    }
  }, [documentId, activeAnalysisItem]);

  const fetchTabData = async () => {
    try {
      setIsLoading(true);

      const optionMap = {
        "Text": "Text",
        "Citations": "Citations",
        "Original PDF": "Original Document",
        "Summary": "Summary",
      };

      const option = optionMap[activeAnalysisItem];
      if (!option) return;

      const documentId = localStorage.getItem('act_document_id');
      if (!documentId) {
          console.error("Document ID not found. Please upload the document first.");
          return;
      }

      const response = await axios.get(`${process.env.REACT_APP_PROD_AI_URL}/chat/act/${documentId}/content`, {
        params: { option }
    });

      const content = response.data.content ? response.data.content : response.data;
      setData(content);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData("Error fetching content. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnalysisItemClick = (item) => {
    setActiveAnalysisItem(item);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendClick = async () => {
    if (!inputValue.trim()) return;

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const sessionId = localStorage.getItem("sessionId");
    const constitutionDocumentId = documentId || localStorage.getItem("act_document_id");

    if (!token || !userId || !sessionId || !constitutionDocumentId) {
      console.error(
        "Error: Missing token, user ID, session ID, or Constitution document ID."
      );
      return;
    }

    const newMessage = { sender: "user", content: inputValue };
    setChatMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputValue("");

    try {
      setIsChatLoading(true);

      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("session_id", sessionId);
      formData.append("chat_id", localStorage.getItem("chatId") || "");
      formData.append("act_document_id", constitutionDocumentId);
      formData.append("query", newMessage.content);

      const response = await axios.post(
        `${process.env.REACT_APP_PROD_AI_URL}/chat/act_chat_process`,
        formData
      );

      const aiResponseContent = response.data.initial_response;
      const aiResponse = { sender: "assistant", content: aiResponseContent };
      setChatMessages((prevMessages) => [...prevMessages, aiResponse]);
    } catch (error) {
      console.error("Error sending message:", error.response?.data || error.message);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { sender: "assistant", content: "An error occurred. Please try again." },
      ]);
    } finally {
      setIsChatLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendClick();
    }
  };

  const renderMainContent = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }

    switch (activeAnalysisItem) {
      case "Text":
      case "Citations":
      case "Summary":
        return (
          <div className="markdown-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{data || "No content available"}</ReactMarkdown>
          </div>
        );
      case "Original PDF":
        return pdfBlobUrl ? (
          <iframe
            src={pdfBlobUrl}
            className="addcase-doc"
            title="PDF Document"
            style={{ width: "100%", height: "500px", border: "none" }}
          ></iframe>
        ) : (
          <p>No document available</p>
        );
      default:
        return <p>Select an analysis section to view content.</p>;
    }
  };

  return (
    <div className="addcase">
      <div className="addcase-header">
        <button onClick={() => navigate(-1)} className="addcase-back-button">
          <img src={rightIcon} alt="" className="back-icon" />
        </button>
        <div className="addcase-title">
          <h2>{fileName || "Constitution Document"} </h2>
        </div>
        <div className="analysis-buttons">
          <button
            className="addcase-header-button"
            onClick={() => window.open(pdfBlobUrl, "_blank")}
          >
            Original Source
          </button>
          <button
            className="addcase-header-button"
            onClick={() => window.open(pdfBlobUrl, "_blank")}
          >
            Download Pdf
          </button>
        </div>
      </div>
      <div className="addcase-analysis-section">
        <div className="addcase-analysis-header">
          {["Text", "Original PDF", "Citations", "Summary"].map((item, index) => (
            <div
              key={index}
              className={`analysis-item ${activeAnalysisItem === item ? "active" : ""}`}
              onClick={() => handleAnalysisItemClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="addcase-main-content">
          <div className="addcase-analysis-document">{renderMainContent()}</div>
          <div className="addcase-chat-section">
            <h3 className="addcase-chat-title">Ask Karma</h3>
            <div className="addcase-divider"></div>
            <div className="addcase-chat-display" ref={chatDisplayRef}>
              {chatMessages.map((msg, index) => (
                <div key={index} className={`chat-message ${msg.sender}`}>
                  <div className={`message-bubble ${msg.sender}`}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {msg.content}
                    </ReactMarkdown>
                  </div>
                </div>
              ))}
              {isChatLoading && (
                <div className="loading-indicator">Karma AI is typing...</div>
              )}
            </div>
            <div className="addcase-chat-prompt">
              <div className="chatbox-input-wrapper">
                <input
                  type="text"
                  className="chatbox-input"
                  placeholder="Type something"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                <div className="chatbox-buttons">
                  <button className="chatbox-send" onClick={handleSendClick}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddConstitution;
