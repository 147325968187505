import { createContext, useState } from 'react';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatData, setChatData] = useState("");

  // Function to reset the chat data
  const askKarmaReset = () => {
    setChatData({
      chat_id: "",
      sessionId: null,
      chat_name: 'Untitled',
      chat_history: []
    });
    // Also clear any persisted chat-related data

    localStorage.removeItem('mainSection_chatMessages');
    localStorage.removeItem('mainSection_showActionButtons');
    localStorage.removeItem('mainSection_lastMessageTime');
    localStorage.removeItem('mainSection_chatName');
    localStorage.removeItem('chatName');
  };

  return (
    <ChatContext.Provider value={{ chatData, setChatData, askKarmaReset }}>
      {children}
    </ChatContext.Provider>
  );
};
