import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API requests
import '../css/Sidebar.css';
import keyIcon from '../icons/ask-karma-icon.svg';
import recentChatIcon from '../icons/recent-chats-icon.svg';
import myCaseIcon from '../icons/my-case-icon.svg';
import chatBubble from '../icons/chat-bubble-icon.svg';
import helpIcon from '../icons/help-icon.svg';
import bareActsIcon from '../icons/bare-acts-icon.svg';
import tutorialIcon from '../icons/tutorial-icon.svg';
import karmaDocsIcon from '../icons/karma-docs-icon.svg';
import settingsIcon from '../icons/settings-icon.svg';
import toggleIcon from '../icons/right-icon.svg';
import fullLogo from "../icons/finalogobil.png";
import logo from "../icons/logo.png";
import chatIcon from "../icons/chat-icon.svg"
import gavelIcon from "../icons/gavel-icon.svg"
import backIcon from "../icons/Back-button-icon.svg";
import { ChatContext } from './ChatContext'; // Import context

const Sidebar = ({ isMobileVisible, toggleMobileSidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate(); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { setChatData, askKarmaReset } = useContext(ChatContext); // Access context to set new chat data

  const userId = localStorage.getItem('userId'); 
  const sessionId = localStorage.getItem('sessionId');

  // Toggle sidebar collapse for desktop
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Check if mobile view on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Only show sidebar on mobile when visible
  if (isMobile && !isMobileVisible) {
    return null; // Hide sidebar in mobile if not toggled open
  }

  // Function to start a new chat by calling the API
  const startNewChat = async () => {
    try {
      // Reset chat state in MainSection
      askKarmaReset();

      const response = await axios.post(`${process.env.REACT_APP_PROD_AI_URL}/chat/start_new_chat`, {
        user_id: userId,
        session_id: sessionId
      });

      const newChatId = response.data.chat_id;

      // Set the new chat data in the context to update home page content
      setChatData({
        chat_id: newChatId,
        session_id: sessionId,
        chat_name: 'Chat Name',
        chat_history: []
      });

      // Store the new chat_id in localStorage
      localStorage.setItem('chat_id', newChatId);

      // Navigate to the home page to start the new chat
      navigate('/home');
    } catch (error) {
      console.error("Error starting new chat:", error);
    }
};


  // Navigation functions
  const goToPage = (path) => {
    navigate(path);
    if (isMobile) {
      toggleMobileSidebar(); // Close sidebar after navigation in mobile view
    }
  };

  // Function to navigate home when logo is clicked
  const handleLogoClick = () => {
    navigate('/home');
    if (isMobile) {
      toggleMobileSidebar(); // Close sidebar after navigation in mobile view
    }
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-content">

        {/* Sidebar Logo */}
        {isCollapsed && (
          <div className="sidebar-logo" onClick={handleLogoClick}>
            <img src={logo} alt="Logo" className="logo-icon" />
          </div>
        )}
        <div className="sidebar-title" onClick={handleLogoClick}>
          {!isCollapsed && <img src={fullLogo} alt="Karma AI Logo" className="karma-ai-logo" />}
        </div>

        {/* Ask Karma Button */}
        <div className="ask-karma-button" onClick={startNewChat}>
          <img src={recentChatIcon} alt="recent chat icon" className="case-icon" />
          {!isCollapsed && <div className="ask-karma-text">Ask Karma</div>}
        </div>

        {/* Sidebar Items */}
        <div className="sidebar-list">
          <div className="sidebar-item" onClick={() => goToPage('/recentchats')}>
            <img src={chatIcon} alt="recent chat icon" className="case-icon" />
            {!isCollapsed && <span className="sidebar-text">Recent Chats</span>}
          </div>
          <div className="sidebar-item" onClick={() => goToPage('/casefinder')}>
            <img src={gavelIcon} alt="case finder icon" className="case-icon" />
            {!isCollapsed && <span className="sidebar-text">Judgments</span>}
          </div>
          <div className="sidebar-item" onClick={() => goToPage('/actsfinder')}>
            <img src={bareActsIcon} alt="bare acts icon" className="sidebar-icon" />
            {!isCollapsed && <span className="sidebar-text">Law Library</span>}
          </div>
          <div className="sidebar-divider"></div>
          <div className="sidebar-item" onClick={() => goToPage('/mycase')}>
            <img src={myCaseIcon} alt="my case icon" className="case-icon" />
            {!isCollapsed && <span className="sidebar-text">My Case</span>}
          </div>
          <div className="sidebar-item" onClick={() => goToPage('/karmadocs')}>
            <img src={karmaDocsIcon} alt="karma docs icon" className="sidebar-icon" />
            {!isCollapsed && <span className="sidebar-text">Karma Docs</span>}
          </div>
        </div>

        {/* Profile and Settings */}
        <div className="profile-section">
          <div className="profile-item" onClick={() => goToPage('/settings')}>
            <img src={settingsIcon} alt="Settings Icon" className="settings-icon" />
            {!isCollapsed && <span className='profile-text'>Settings</span>}
          </div>
          <div className="profile-item">
            <div className="profile-icon">N</div>
            {!isCollapsed && <span className='profile-text'>Support</span>}
          </div>
          
          {/* Desktop Collapse Button */}
          {!isMobile && (
            <button className="toggle-button" onClick={handleToggle}>
              <img className="right-arrow-toggle" src={toggleIcon} alt="Toggle Sidebar" />
            </button>
          )}
        </div>

        {/* Mobile Close Button */}
        {isMobile && (
          <button className="mobile-sidebar-close" onClick={toggleMobileSidebar}>
            <img src={backIcon} alt="close icon" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
