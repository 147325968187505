import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/Register.css";
import fullLogo from "../icons/finalogobil.png";

const Register = () => {
  const [passwordVisibility, setPasswordVisibility] = useState([false, false]);
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [salutation, setSalutation] = useState("Mr.");
  const [profession, setProfession] = useState("Other");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firm, setFirm] = useState("");
  const [isLawyer, setIsLawyer] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeTrial, setAgreeTrial] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (registrationSuccess) {
      setShowModal(true);
    }
  }, [registrationSuccess]);

  const togglePasswordVisibility = (index) => {
    const updatedVisibility = [...passwordVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisibility(updatedVisibility);
  };

  // Validation functions
  const validateFirstName = (name) => {
    if (/[^a-zA-Z\s]/.test(name)) {
      setErrors((prev) => ({
        ...prev,
        firstName: "First name should only contain letters and spaces",
      }));
    } else {
      setErrors((prev) => ({ ...prev, firstName: null }));
    }
  };

  const validateLastName = (name) => {
    if (/[^a-zA-Z\s]/.test(name)) {
      setErrors((prev) => ({
        ...prev,
        lastName: "Last name should only contain letters and spaces",
      }));
    } else {
      setErrors((prev) => ({ ...prev, lastName: null }));
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prev) => ({ ...prev, email: "Email is not valid" }));
    } else {
      setErrors((prev) => ({ ...prev, email: null }));
    }
  };

  const validatePhoneNumber = (number) => {
    if (/[^0-9]/.test(number)) {
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Phone number should only contain numbers",
      }));
    } else if (number.length !== 10) {
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Phone number must be exactly 10 digits",
      }));
    } else {
      setErrors((prev) => ({ ...prev, phoneNumber: null }));
    }
  };


  const handleInputChange = (setter, fieldName) => (e) => {
    setter(e.target.value);
    setErrors((prev) => ({ ...prev, [fieldName]: null })); // Reset the specific field error as the user types
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Reset errors and show loading state
    setErrors({});
    setIsLoading(true);

    // Perform validation checks
    let hasErrors = false;

    if (password !== retypePassword) {
      setErrors((prev) => ({ ...prev, retypePassword: "Passwords do not match" }));
      hasErrors = true;
    }
    if (!firstName) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required" }));
      hasErrors = true;
    }
    if (!lastName) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required" }));
      hasErrors = true;
    }
    if (!email) {
      setErrors((prev) => ({ ...prev, email: "Email is required" }));
      hasErrors = true;
    }
    if (!phoneNumber) {
      setErrors((prev) => ({ ...prev, phoneNumber: "Phone number is required" }));
      hasErrors = true;
    }
    if (!city) {
      setErrors((prev) => ({ ...prev, city: "City is required" }));
      hasErrors = true;
    }
    if (!profession) {
      setErrors((prev) => ({ ...prev, profession: "Profession is required" }));
      hasErrors = true;
    }
    if (!password) {
      setErrors((prev) => ({ ...prev, password: "Password is required" }));
      hasErrors = true;
    }
    if (!agreeTerms) {
      setErrors((prev) => ({ ...prev, agreeTerms: "You must agree to the terms and conditions" }));
      hasErrors = true;
    }

    // If there are validation errors, reset loading state and return
    if (hasErrors) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_PROD_BACKEND_URL}/auth/register`, {
        name: `${salutation} ${firstName} ${lastName}`,
        email,
        password,
        phoneNumber,
        location: city,
        isLawyer,
        profession,
        firm,
      });

      console.log(response.data.message);
      setRegistrationSuccess(true);
    } catch (error) {
      console.error(
        "Error registering user:",
        error.response?.data?.error || error.message || error
      );
      setErrors((prev) => ({
        ...prev,
        form: error.response?.data?.error || "Failed to register. Please try again.",
      }));
    } finally {
      setIsLoading(false); // Always reset the loading state
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/login");
  };
  return (
    <div className="register-container">

      {/* Loading Modal */}
      {isLoading && (
        <div className="modal-overlay">
          <div className="modal-box">
            <div className="spinner"></div>
            <p>Registering, please wait...</p>
          </div>
        </div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Success</h2>
            <p>Registered successfully! Your account is under review and will be activated within 24 hours.</p>
            <button onClick={handleCloseModal} className="register-close-button">OK</button>
          </div>
        </div>
      )}

      <div className="register-left">
        <form className="register-form" onSubmit={handleRegister}>
          <h2 className="form-title">Register</h2>

          {/* Salutation Field
          <div className="form-group">
            <label>Salutation <span className="required-asterisk">*</span></label>
            <select
              value={salutation}
              onChange={handleInputChange(setSalutation, "salutation")}
            >
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Ms.">Ms.</option>
            </select>
          </div> */}

          <div className="form-row-name">
            {/* Salutation Field */}
            <div className="form-group-sal">
              <label>Salutation <span className="required-asterisk">*</span></label>
              <select
              className="form-salutation"
                value={salutation}
                onChange={handleInputChange(setSalutation, "salutation")}
              >
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Ms.">Ms.</option>
              </select>
            </div>

            <div className="form-group-first">
              <label>First Name <span className="required-asterisk">*</span></label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => {
                  handleInputChange(setFirstName, "firstName")(e);
                  validateFirstName(e.target.value);
                }}
              />
              {errors.firstName && <p className="error-text">{errors.firstName}</p>}
            </div>
            <div className="form-group-last">
              <label>Last Name <span className="required-asterisk">*</span></label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => {
                  handleInputChange(setLastName, "lastName")(e);
                  validateLastName(e.target.value);
                }}
              />
              {errors.lastName && <p className="error-text">{errors.lastName}</p>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Email <span className="required-asterisk">*</span></label>
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  handleInputChange(setEmail, "email")(e);
                  validateEmail(e.target.value);
                }}
              />
              {errors.email && <p className="error-text">{errors.email}</p>}
            </div>
            <div className="form-group">
              <label>Phone Number <span className="required-asterisk">*</span></label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => {
                  handleInputChange(setPhoneNumber, "phoneNumber")(e);
                  validatePhoneNumber(e.target.value);
                }}
              />

              {errors.phoneNumber && <p className="error-text">{errors.phoneNumber}</p>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>City <span className="required-asterisk">*</span></label>
              <input
                type="text"
                value={city}
                onChange={handleInputChange(setCity, "city")}
              />
              {errors.city && <p className="error-text">{errors.city}</p>}
            </div>
            <div className="form-group">
              <label>Profession <span className="required-asterisk">*</span></label>
              <select
                value={profession}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  handleInputChange(setProfession, "profession")(e);
                  setIsLawyer(selectedValue === "lawyer");
                }}
              >
                <option value="lawyer">Lawyer</option>
                <option value="law-student">Law Student</option>
                <option value="college">College</option>
                <option value="other">Other</option>
              </select>
              {errors.profession && <p className="error-text">{errors.profession}</p>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group full-width">
              <label>Firm/College Name</label>
              <input
                type="text"
                value={firm}
                onChange={handleInputChange(setFirm, "firm")}
              />
              {errors.firm && <p className="error-text">{errors.firm}</p>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group full-width">
              <label>Password <span className="required-asterisk">*</span></label>
              <div className="password-container">
                <input
                  type={passwordVisibility[0] ? "text" : "password"}
                  value={password}
                  onChange={handleInputChange(setPassword, "password")}
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility(0)}
                  className="toggle-password-btn"
                >
                  {passwordVisibility[0] ? "Hide" : "Show"}
                </button>
              </div>
              {errors.password && <p className="error-text">{errors.password}</p>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group full-width">
              <label>Retype Password <span className="required-asterisk">*</span></label>
              <div className="password-container">
                <input
                  type={passwordVisibility[1] ? "text" : "password"}
                  value={retypePassword}
                  onChange={handleInputChange(setRetypePassword, "retypePassword")}
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility(1)}
                  className="toggle-password-btn"
                >
                  {passwordVisibility[1] ? "Hide" : "Show"}
                </button>
              </div>
              {errors.retypePassword && (
                <p className="error-text">{errors.retypePassword}</p>
              )}
            </div>
          </div>

          {/* Terms and conditions checkbox */}
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={agreeTerms}
              onChange={() => setAgreeTerms(!agreeTerms)}
            />
            <label>I agree to the Terms and Conditions and Trial Period Policy. <span className="required-asterisk">*</span></label>
            {errors.agreeTerms && <p className="error-text">{errors.agreeTerms}</p>}
          </div>

          {/* Trial period checkbox */}
          {/* <div className="checkbox-container">
            <input
              type="checkbox"
              checked={agreeTrial}
              onChange={() => setAgreeTrial(!agreeTrial)}
            />
            <label>I agree to the trial period <span className="required-asterisk">*</span></label>
            {errors.agreeTrial && <p className="error-text">{errors.agreeTrial}</p>}
          </div> */}

          {errors.form && <p className="error-text">{errors.form}</p>}
          <button type="submit" className="register-button">
           Register 
          </button>

          <div className="login-link-container">
            <p>Already registered? <a href="/login">Login here</a></p>
          </div>
        </form>
      </div>

      <div className="register-right">
        <div className="covering">
          <img className="karma-full-logo" src={fullLogo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Register;
