import React, { useState, useEffect, useContext } from 'react';
import '../css/RecentChats.css';
import { FaSearch } from 'react-icons/fa';
import ThreadOptionsDropdown from './ThreadDropdown';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChatContext } from './ChatContext';
import caseFinderHeaderIcon from "../icons/casefinder-header-icon.svg";
import docIcon from "../icons/document-icon.svg";
import archiveIcon from "../icons/archive-chat-icon.svg";
import threedotIcon from "../icons/three-dots-icon.svg";
import thrashIcon from "../icons/thrash-icon.svg";
import hamburgerIcon from "../icons/hamburger-icon.svg";
import searchIcon from "../icons/search-icon.svg";

// Loading Modal Component
const LoadingModal = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-box">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        </div>
    );
};

function RecentChats({ toggleMobileSidebar }) {
    const [chatHistories, setChatHistories] = useState([]);
    const [sources, setSources] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state for the modal
    const navigate = useNavigate();
    const { setChatData } = useContext(ChatContext);

    // Function to fetch user chat history
    useEffect(() => {
        const fetchUserChatHistory = async () => {
            try {
                const userId = localStorage.getItem('userId');
                if (!userId) {
                    console.error('User ID not found in localStorage');
                    return;
                }

                const response = await axios.get(`${process.env.REACT_APP_PROD_AI_URL}/chat/user_chat_history`, {
                    params: { user_id: userId }
                });

                setChatHistories(response.data.chats);  // Store all chats with session_id and chat_id

                const allSources = response.data.chats.flatMap(chat =>
                    chat.chat_history.flatMap(msg => msg.sources || [])
                );
                setSources(allSources);
            } catch (error) {
                console.error('Error fetching user chat history:', error);
            } finally {
                setLoading(false); // Hide loading modal once data is fetched
            }
        };

        fetchUserChatHistory();
    }, []);

    // Navigate to the archived chats page
    const goToArchive = () => {
        navigate('/archive');
    };

    const loadChatHistory = (chat) => {
        const chatSources = chat.chat_history.flatMap(msg => msg.sources || []);
        setChatData({
            ...chat,
            sourceDocuments: chatSources,
        });
        navigate('/home');
    };

    const handleArchive = async (sessionId) => {
        if (!sessionId) {
            console.error("Session ID is null or undefined, cannot archive");
            return;
        }
        try {
            await axios.post(
                `${process.env.REACT_APP_PROD_AI_URL}/chat/archive_chat`,
                null,
                { params: { session_id: sessionId } }
            );

            setChatHistories(prev => prev.filter(chat => chat.session_id !== sessionId));
        } catch (error) {
            console.error('Error archiving chat:', error);
        }
    };

    const handleDelete = async (sessionId, chatId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_PROD_AI_URL}/chat/delete_chat`, {
                params: { session_id: sessionId, chat_id: chatId }
            });
            setChatHistories(prev => prev.filter(chat => chat.chat_id !== chatId));
        } catch (error) {
            console.error('Error deleting chat:', error);
        }
    };

    return (
        <div className="recentchat">
            <LoadingModal isLoading={loading} /> {/* Show loading modal when loading */}

            <div className="recentchat-header" onClick={goToArchive}>
                <button className="mobile-hamburger" onClick={toggleMobileSidebar}>
                    <img src={hamburgerIcon} alt="Hamburger Icon" />
                </button>
                <h3>{chatHistories.length} Recent Chats</h3>
                <div className='recentchat-header-left'>
                    <img src={archiveIcon} alt="" />
                    <h3>Archive Chat</h3>
                </div>
            </div>

            <div className="section-divider"></div>

            <div className="casefinder-search-bar">
                <div className="casefinder-search-wrapper">
                    <img className="casefinder-search-icon" src={searchIcon} alt="" />

                    <input type="text" placeholder="Search recent chats" />
                </div>
            </div>
            <div id="divider"></div>

            


            <div className="recentchat-content" >
                <div className="recentchat-threads-section">
                    {chatHistories.map((session, index) => (
                        <div
                            key={index}
                            className="recentchat-thread-item"
                            onClick={() => loadChatHistory(session)}
                        >
                            <div className="recentchat-thread-title">
                                {session.chat_name || `Session: ${session.chat_id}`}
                            </div>
                            <div className="recentchat-thread-meta">
                                <div className="thread-meta-left">
                                    <span>{session.chat_history?.length || 0} messages</span>
                                </div>
                                <div
                                    className="thread-meta-right"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <ThreadOptionsDropdown
                                        onArchive={() => handleArchive(session.session_id)}
                                        onDelete={() => handleDelete(session.session_id, session.chat_id)}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='recentchat-right-panel'>
                    <div className="activity-log">
                        <h2>Activity Log</h2>
                        <p className="subtitle">Last viewed acts</p>
                        <div className="document-cards">
                            {sources.map((source, index) => (
                                <div key={index} className="document-card">
                                    <img className="document-icon" src={docIcon} alt="document icon" />
                                    <div className="document-details">
                                        <p className="document-name">{source}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecentChats;
